<template>
  <div class="profile-change-password">
    <h1 class="title">{{$t('Change Password')}}</h1>

    <ValidationObserver ref="validator">
      <form class="mt-5" @submit.prevent="change">
        <RFValidation :name="$t('Old Password')" rules="required">
          <RFInput :label="$t('Old Password')" type="password" v-model="oldPassword"/>
        </RFValidation>

        <RFValidation :name="$t('New Password')" rules="required|minLength|password">
          <RFInput :label="$t('New Password')" type="password" v-model="newPassword"/>
        </RFValidation>

        <RFValidation :name="$t('Confirm Password')" rules="required">
          <RFInput :label="$t('Confirm Password')" type="password" v-model="confirmPassword"/>
        </RFValidation>

        <RFButton class="mt-3" :label="$t('Save')" v-if="!loading"/>
        <RFLoader class="mt-3" :size="30" color="#24425B" v-else/>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFButton from '@/components/forms/RFButton'
import RFValidation from '@/components/forms/RFValidation'
import RFLoader from '@/components/forms/RFLoader'

import {userService} from '@/services'
import {preventCutCopy} from '@/helpers/functions'

export default {
  name: 'ProfileChangePassword',
  components: {
    RFInput,
    RFButton,
    RFValidation,
    RFLoader,
  },
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      loading: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.userStore.user
    },
    email() {
      return this.user.email
    }
  },
  mounted() {
    preventCutCopy()
  },
  methods: {
    async change() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        if (this.newPassword !== this.confirmPassword) {
          this.$toasted.error(this.$i18n.t(`New password and confirm password doesn't match`))
          return false
        }

        if (this.oldPassword === this.newPassword) {
          this.$toasted.error(this.$i18n.t(`Old password and new password are equals`))
          return false
        }

        this.loading = true

        const user = {
          email: this.email,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        }

        const changed = await userService.updatePassword(this.user.id, user)

        if (changed) {
          this.$toasted.success(this.$i18n.t(`Password successfully changed!`))
          this.$router.push({ name: 'your-info' })
        }

        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-change-password {
  padding: 40px 0;

  .title {
    @include font-style($montserrat, 'medium', $font-20);
    color: $dark-gray;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: .5px solid $dark-gray;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .profile-change-password {
    padding: 0 40px;
  }
}
</style>
